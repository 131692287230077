<template>
  <div>
    <h5 class="pb-2">Cadastro de Macros</h5>
    <div class="_main">
      <CCol sm="12" md="12" class="_no-padding">
        <CCard class="_card-container">
          <CCardHeader class="_card-header-parent mt-2" v-if="pages.length !== 0">
            <div>
              <strong class="mr-2">Componente:</strong>                        
              <CButton v-for="(item, index) in pages" :key="index" size="sm" color="blue-dark" class="_btn-parent" @click="reset(index)">
                <i class="far fa-dot-circle mr-2"></i> {{item.descriptionKey}}
              </CButton>         
            </div>
          </CCardHeader>
          <CCardBody class="_card-body">
            <Macro v-if="pages.length === 0" @set-hierarchy-level="hierarchyLevel = $event"></Macro>
            <!--<div class="_main-parent">
              <CTabs :active-tab.sync="activeTab" v-model="activeTab">
                <CTab v-if="pages.length === 1">
                  <template slot="title">Parâmetros do Componente </template>
                  <ComponentParameter :resetContainer="resetContainer" :parent="listParent[0]" @show-badge-container="parent = $event" @set-breadcrumb="breadcrumb = $event" ></ComponentParameter>
                </CTab>
              </CTabs>
            </div>-->
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  </div>
</template>

<script>
import Macro from './Macro'

export default {
  name: 'MacroContainer',
  components: {Macro,},
  computed: {
    getFirstPage() {
      return this.pages[0];
    },    
    getLastPage() {
      return this.pages[this.pages.length-1];
    },
    activeLevel() {
      return this.pages.length
    }
  },
  data() {
    return {
      activeTab: 0,
      hierarchyLevel: {},
      pages: [],
      tabs:[
        {"parentName":"Macro","tabIndex":0},
      ]
    }
  },
  methods: {
    setTabIndex(payload){
      let tab = this.tabs.filter(i => (i.parentName === payload.parentName));
      this.activeTab = tab[0].tabIndex
    },
    reset(payload) {
      if(payload === 0) {
        this.pages = []
      } else {
        this.pages.splice(payload);
        this.setTabIndex(this.getLastPage)
      } 
    }
  },
  watch: {
    hierarchyLevel: {
      immediate: true,
      handler(hierarchyLevel) {
        if (Object.entries(this.hierarchyLevel).length !== 0) {
          this.pages.push(hierarchyLevel)
          this.setTabIndex(hierarchyLevel)
        }
      },
    },
  }
}
</script>
